import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Dropdown from "../components/dropdown"
import titreRubrique from "../assets/images/accueil/puce_titre_rubrique.gif"
import mainTitle from "../assets/images/titres/notre_equipement_EC3D_.gif"
import locaux from "../assets/images/titres/nos_locaux_EC3D.gif"
import 'react-slidedown/lib/slidedown.css'
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import "./equipement.css"

const equipementNarcastetPage = ({data}) => (
  <Layout>
    <SEO title="Équipements et locaux - Narcastet - EC3D" />
    <div id="equipement" class="rubrique">
      <div id="titre">
          <img src={titreRubrique} width="25" height="32" alt="puce titre rubrique"/>
          <span class="h1"><img src={mainTitle} width="518" height="32" alt="Rubrique Notre équipement - EC3D" /></span>
          <p class="titre-texte h2">
          </p>
      </div>
              
      <div id="contenu">

        <div class="block main-block">
          <div class="inner-block">
            <span class="h1">{ data.allContentfulNotreEquipement.edges[0].node.subtitle }</span>
            <div class="h3" dangerouslySetInnerHTML={{ __html: documentToHtmlString(data.allContentfulNotreEquipement.edges[0].node.introduction.json) }}>
            </div>
            <div class="dropdown-container">
              {data.allContentfulNotreEquipement.edges[0].node.quipements.map(equipement => (
                <Dropdown title={equipement.title} images={equipement.images} content={equipement.texte ? equipement.texte.json: null}/>
              ))}
            </div>
          </div>
        </div>

        <div class="block side-block">
          <div class="inner-block">
            <div class="block-title h1"><img src={locaux} width="155" height="22" alt="Innovation EC3D" />
            </div>
            <div class="h3">
              <div dangerouslySetInnerHTML={{ __html: documentToHtmlString(data.allContentfulNotreEquipement.edges[0].node.buildings.buildings.json) }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default equipementNarcastetPage

export const query = graphql`
    query {
      allContentfulNotreEquipement(filter: {title: {regex: "/Narcastet/"}}) {
        edges {
          node {
            id,
            subtitle,
            introduction {
              json
            },
            quipements{
              title,
              images{
                file{
                  url
                }
              },
              texte{
                json
              }
            },
            buildings{
              buildings{
                json
              }
            }
          }
        }
      }
    }
  `