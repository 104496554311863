import React from "react"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

class Dropdown extends React.Component {
  state = {
    droped: `false`
  }
  handleDropdown = event => {
      this.setState(current => ({droped: !current.droped}));
  }
  render() {
    return (
        <div class="dropdown-element">
            <div class="dropdown-header" onClick={this.handleDropdown} role = "button" tabIndex={0}>
            <div class="dropdown-title">
                {this.props.title}
            </div>
            </div>
            <div class={"dropdown-content " + (this.state.droped ? 'show' : 'hidden')}>
                <div class="dropdown-content-inner">
                  {this.props.images &&
                    <div class="dropdown-images">
                      {this.props.images.map(image => (
                        <img src={image.file.url} alt={image.file.url}/>
                      ))}
                    </div>
                  }
                  <div class="dropdown-text h4" dangerouslySetInnerHTML={{ __html: documentToHtmlString(this.props.content) }}>
                  </div>
                </div>
            </div>
        </div>
    )
  }
}
export default Dropdown